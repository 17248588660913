import React from "react";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid';
import { StaticImage } from "gatsby-plugin-image";
import ThemeSelector from "./ThemeSelector";
import backgroudHeader from "../images/Rizon_Parein_isobar2_02_4K.jpg";

const Header = ({ siteTitle, setSelectedTheme, selectedTheme }) => (
  <header
    style={{
      backgroundImage: `url(${backgroudHeader})`,
      paddingRight: `1.45rem`,
      alignItems: "center",
    }}
  >
    <div style={{ margin: `1.45rem` }}>
      <Grid container spacing={2}>
        <Grid item xs={2} md={2}>
          {/* <StaticImage src="../images/Isobar_White_CMYK_300dpi.png" alt="isobar logo" /> */}
          <StaticImage src="../images/badge-nft_Transparent-white.png" alt="badge logo" />
        </Grid>
        <Grid item xs={10} md={11}></Grid>
        <Grid item xs={1} md={2}>
        </Grid>
        <Grid item xs={9} md={8}>
          <h1 style={{ color: `#FFFF` }}>
            {siteTitle}
          </h1>
        </Grid>
        <Grid item xs={2} md={2}>
          <ThemeSelector setter={setSelectedTheme} theme={selectedTheme} />
        </Grid>
      </Grid>
    </div>
  </header >
)


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
