import React, { useState, useEffect } from "react";
import styled from "styled-components";
import _ from 'lodash';
import { getFromLS } from '../utils/storage';
import { useTheme } from '../hook/useTheme';
import SwitchTheme from "./SwitchTheme";

const SwitchDiv = styled.div`
  flex: inherit;
  padding: 12px 24px;
  font-size: 14px;
  border-radius: 4px;
  margin-top: 5px;
  margin-right: 1.5rem;
`;

export default (props) => {
  const themesFromStore = getFromLS('all-themes');
  const [data] = useState(themesFromStore?.data);
  const [otherTheme, setOtherTheme] = useState();
  const { setMode } = useTheme();

  const themeSwitcher = selectedTheme => {
    setMode(selectedTheme);
    props.setter(selectedTheme);
  };

  useEffect(() => {
    let theme;
    const dataKeys = _.keys(data);
    dataKeys.forEach(element => {
      if (data[element].name !== props.theme.name) {
        theme = data[element];
      }
    });
    setOtherTheme(theme);
  }, [data, props.theme]);

  return (
    <>
      {
        otherTheme ? (
          <SwitchDiv>
            <SwitchTheme onChange={(theme) => themeSwitcher(otherTheme)} />
          </SwitchDiv>
        ) : ''}
    </>
  );
};
