/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import styled, { ThemeProvider } from "styled-components";
import Header from "./header";
import { GlobalStyles } from './GlobalStyles';
import { useTheme } from '../hook/useTheme';


const Container = styled.div`
  margin: 5px auto 5px auto;
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const { theme, themeLoaded } = useTheme();
  const [selectedTheme, setSelectedTheme] = useState(theme);

  useEffect(() => {
    setSelectedTheme(theme);
  }, [themeLoaded]);

  return (
    <>
      {
        themeLoaded && <ThemeProvider theme={selectedTheme}>
          <GlobalStyles />
          <Header siteTitle={data.site.siteMetadata?.title || `Title`} setSelectedTheme={setSelectedTheme} selectedTheme={selectedTheme} />
          <Container style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0 1.0875rem 1.45rem`,
          }}>
            <main>{children}</main>
            <footer
              style={{
                marginTop: `2rem`,
              }}
            >
            </footer>
          </Container>
        </ThemeProvider>
      }
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
